// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-certified-tester-js": () => import("./../../../src/pages/certified-tester.js" /* webpackChunkName: "component---src-pages-certified-tester-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-bootcamp-js": () => import("./../../../src/pages/project-bootcamp.js" /* webpackChunkName: "component---src-pages-project-bootcamp-js" */),
  "component---src-pages-project-color-game-js": () => import("./../../../src/pages/project-color-game.js" /* webpackChunkName: "component---src-pages-project-color-game-js" */),
  "component---src-pages-project-ecommerce-js": () => import("./../../../src/pages/project-ecommerce.js" /* webpackChunkName: "component---src-pages-project-ecommerce-js" */),
  "component---src-pages-project-meal-planner-js": () => import("./../../../src/pages/project-meal-planner.js" /* webpackChunkName: "component---src-pages-project-meal-planner-js" */),
  "component---src-pages-project-todo-list-js": () => import("./../../../src/pages/project-todo-list.js" /* webpackChunkName: "component---src-pages-project-todo-list-js" */)
}

